
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import { useRoute, useRouter } from 'vue-router';
import BasicModal from '@/components/modals/BasicModal.vue'
import { toastError, toastSuccess } from '@/core/services/Alert';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'verify-members',
    components: {
        BasicModal
    },

    setup() {
        const store = useStore()
        const drawers = ref(true)
        const router = useRouter()
        const modalBatal = ref<any>(false);
        const route = useRoute()
        const { t } = useI18n();
        const submitButton = ref<HTMLElement | null>(null);
        const userData = ref({
            _id: '',
            name: '',
            username: '',
            email: '',
            phone: '',
            nik: '',
            role: {
                _id: '',
                code: '',
                name: '',
            },
            tenants: [
                {
                    _id: "",
                    name: "",
                    workspaces: [
                        {
                            _id: "",
                            name: "",
                            channels: [
                                {
                                    _id: "",
                                    name: ""
                                }
                            ]
                        }
                    ]
                }
            ],
            created_at: '',
            updated_at: '',
            email_verified_at: '',
            admin_verified_at: '',
            is_active: false,
        })

        const drawer = computed({
            get: () => store.getters.memberDrawerState,
            set: (v) => store.dispatch(Actions.UPDATE_MEMBER_DRAWER_STATE, v),
        });

        function closeDrawer() {
            router.replace({ name: 'members' })
        }

        const onSave = () => {
            modalBatal.value = true
        }

        const verifyMember = (id, type) => {
            submitButton.value?.setAttribute("data-kt-indicator", "on");
            const payload = {
                id, type
            }
            store.dispatch(Actions.VERIFY_MEMBER, payload).then(() => {
                toastSuccess(`${t('common.success')} ${type == 'approve' ? t('membersPage.textVerifyBtn') : t('membersPage.textDeclineBtn')} ${t('membersPage.textMembers')}`)
                closeDrawer()
            }).catch((error) => {
                toastError(`${t('common.failed')} ${type == 'approve' ? t('membersPage.textVerifyBtn') : t('membersPage.textDeclineBtn')} ${t('membersPage.textMembers')} ${error}`)
            }).finally(() => {
                store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter)
                submitButton.value?.setAttribute("data-kt-indicator", "off");
            })
        }

        onMounted(() => {
            store.dispatch(Actions.GET_MEMBER_DETAIL, route.params.id).then((response) => {
                userData.value = response.data
            })
        })


        return {
            drawer,
            closeDrawer,
            drawers,
            modalBatal,
            onSave,
            userData,
            verifyMember,
            submitButton,
        }
    }
})
